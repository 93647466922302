.SideBar {
  width: calc(100vw - 16px - 20px);
  height: 50px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  font-family: Circular, sans serif;
  //font-size: 19px;
  //font-weight: 700;
  gap: 10px;
}

.SidebarItem {
  height: 45px;
  color: #939DA6;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
}

.SidebarItem:hover {
  color: #283C4E;
}

.ActiveSidebarItem {
  @extend .SidebarItem;
  justify-content: space-between;
  color: #283C4E;
}

.UserButton {
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 6px;
  background: #F5F5F5;
  border: none;
  padding: 10px 20px;
  font-weight: 400;
  cursor: pointer;
  transition: 0.3s;
  font-size: 15px;
}

.UserButton:hover {
  background: #c9c9c9;
}

.TabButton {
  background-color: #FFF;
  padding: 10px;
  color: #000;
  border-radius: 6px;
  cursor: pointer;
}

.TabButtonSelected {
  @extend .TabButton;
  background-color: #273c4e;
  color: #FFF;
}

.TabButton:hover {
  background: #e7e7e7;
}

.TabButtonSelected:hover {
  background-color: #273c4e;
}

.Caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}