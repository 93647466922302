$stepper-size: 20px;

.StepperWrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  //margin-bottom: 20px;
}

.StepperItem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.StepperItem::before {
  position: absolute;
  content: "";
  border-bottom: 4px solid #ccc;
  width: 100%;
  top: calc($stepper-size / 2 - 2px);
  left: -50%;
  z-index: 2;
}

.StepperItem::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: calc($stepper-size / 2);
  left: 50%;
  z-index: 2;
}

.StepCounter {
  position: relative;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $stepper-size;
  height: $stepper-size;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.StepperItem.active {
  font-weight: bold;
}

.StepCounterCompleted {
  @extend .StepCounter;
  background-color: #4bb543;
}

.StepperItemCompleted::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #4bb543;
  width: 100%;
  top: calc($stepper-size / 2);
  left: 50%;
  z-index: 3;
}

.StepperItem:first-child::before {
  content: none;
}

.StepperItem:last-child::after {
  content: none;
}

.PaymentRow {
  font-weight: lighter;
  text-align: center;
}

.FocussedPaymentRow {
  @extend .PaymentRow;
  border: 2px solid black;
  box-sizing: border-box;
}

.PaymentRow > td {
  padding-top: 5px;
  padding-bottom: 5px;
}