$content-width: 98%;

/* The Modal (background) */
.Modal {
  display: none;
  position: fixed; /* Stay in place */
  z-index: 6; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
}

.Modal.Show {
  display: block;
}

/* Modal Content */
.ModalContent {
  overflow: hidden;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  position: fixed;
  bottom: 0;
  left: calc((100% - $content-width) / 2);
  background-color: #fefefe;
  width: $content-width;
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s
}

/* The Close Button */
.Close {
  color: #283C4E;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.Close:hover,
.Close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.ModalHeader {
  padding: 2px 16px;
  color: #283C4E;
}

.ModalBody {
  padding: 2px 16px;
}

.ModalFooter {
  padding: 2px 16px;
  color: black;
}

/* Add Animation */
@-webkit-keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0
  }
  to {
    bottom: 0;
    opacity: 1
  }
}

@keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0
  }
  to {
    bottom: 0;
    opacity: 1
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@keyframes fadeIn {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}