.BasicSection {
  margin-bottom: 30px;
}

.PaymentStage {
  border: solid 3px #6b788c;
  border-radius: 12px;
  margin-bottom: 5px;
  padding: 20px;
}

.PaymentStage > table {
  width: 100%;
}

.PaymentRow {
  //display: flex;
  //gap: 15px;
  //margin-bottom: 15px;
}