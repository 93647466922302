@import '~react-phone-input-2/lib/style.css';
//@import "~tabulator-tables/dist/css/bootstrap/tabulator_bootstrap4.min.css";

$black: #000 !default;

@font-face {
  font-family: 'Circular';
  src: url('../../fonts/Circular/CircularStd-Medium.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Circular';
  src: url('../../fonts/Circular/CircularStd-Black.otf');
  font-weight: 900;
}

@font-face {
  font-family: 'Circular';
  src: url('../../fonts/Circular/CircularStd-Bold.otf');
  font-weight: 700;
}

@font-face {
  font-family: 'Circular';
  src: url('../../fonts/Circular/CircularStd-Book.otf');
  font-weight: 300;
}

@font-face {
  font-family: 'Circular';
  src: url('../../fonts/Circular/CircularStd-Light.otf');
  font-weight: 100;
}

@font-face {
  font-family: 'Circular';
  src: url('../../fonts/Circular/CircularStd-MediumItalic.otf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Circular';
  src: url('../../fonts/Circular/CircularStd-BlackItalic.otf');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Circular';
  src: url('../../fonts/Circular/CircularStd-BoldItalic.otf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Circular';
  src: url('../../fonts/Circular/CircularStd-BookItalic.otf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Circular';
  src: url('../../fonts/Circular/CircularStd-LightItalic.otf');
  font-weight: 100;
  font-style: italic;
}

html {
  font-size: 10pt;
  background-color: black;
}

:root {
  font-size: 14px !important;
}