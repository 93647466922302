$transition-time: 300ms;
$card-padding: 15px;

.Card {
  width: calc(100% - ($card-padding * 2));
  background-color: white;
  border-radius: 12px;
  padding: $card-padding;
}

.CardTitle {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #283C4E;
  cursor: pointer;
  transition: all $transition-time;
}

.CardContainer {
  overflow: hidden;
}

.CardBody {
  margin-top: 0;
  transition: all $transition-time;
}

.CardBody.collapse {
  margin-top: -250%;
}

.Card:has(.CardContainer > .CardBody.collapse) > .CardTitle {
  margin-bottom: 0;
}